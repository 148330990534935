import type { InitOptions } from 'i18next';
import ja from 'app/locales/ja';
import en from 'app/locales/en';

const i18nConfig: InitOptions = {
  lng: process.env.LOCALE,
  supportedLngs: [process.env.LOCALE],
  react: { useSuspense: true },
  load: 'currentOnly',
  defaultNS: 'translation',
  resources: {
    [process.env.LOCALE]: process.env.LOCALE === 'ja' ? ja : en,
  },
  returnEmptyString: false,
};

export default i18nConfig;
