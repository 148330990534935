import { RemixBrowser } from '@remix-run/react';
import { BrowserTracing } from '@sentry/tracing';
import i18nConfig from 'app/helpers/locale';
import { Environment } from 'config/constants';
import i18next from 'i18next';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { startTransition } from 'react';

declare global {
  interface Window {
    __ENV: {
      SENTRY_DSN: string;
      ENVIRONMENT: string;
      NAMESPACE: string;
    };
  }
}

const main = async () => {
  if (window.__ENV.SENTRY_DSN) {
    Sentry.init({
      enabled: window.__ENV.ENVIRONMENT === Environment.PRODUCTION,
      dsn: window.__ENV.SENTRY_DSN,
      integrations: [new BrowserTracing()],
      sampleRate: 1, // Error events
      tracesSampleRate: 1, // Transactions
      environment: window.__ENV.NAMESPACE,
    });
  }

  await i18next.use(initReactI18next).init({
    ...i18nConfig,
  });

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <RemixBrowser />
      </I18nextProvider>,
    );
  });
};

main();
